import './About.css';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import React from 'react'; 
import { Button } from 'primereact/button';
import { Knob } from 'primereact/knob';
import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';

import { useState } from "react";

const initialValue = 0;

const About = () => {
    const [value, setValue] = useState(initialValue)

    const handleChange = (event) => {
        const value = event.value;
        setValue(value);
    };

    return (
     <div>
        <nav>
        <Link to='/'>Home</Link>
        </nav>
        <Knob value={value} onChange={handleChange} />
    </div>
  );
}

export default About;