import './App.css';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import React from 'react'; 
import { Button } from 'primereact/button';

import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import About from './components/About/About';



function App() {
  return (
    <div className="App">
      <Router>
      <header className="App-header">
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
        </Routes>

      </header>
      </Router>
    </div>
  );
}

export default App;
