import './Home.css';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import React from 'react'; 
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function Home() {
  return (
    <div>
        <nav>
        <Link to='about'>About</Link>
        </nav>
        <Button>Test</Button>
    </div>
  );
}

export default Home;