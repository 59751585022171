import './Login.css';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import React from 'react'; 
import { Button} from 'primereact/button';
import { InputText} from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { FloatLabel} from 'primereact/floatlabel';
import { Card } from 'primereact/card';

import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

import { useState } from "react";


const initialRememberMe = false;
const initialpassword = "";
const initialemail = "";

const Login = () => {
    const [checked, setValue] = useState(initialRememberMe);
    const [email, setEmail] = useState(initialemail);
    const [password, setPassword] = useState(initialpassword);

    const passwordEnter = (event) => {setPassword(event.target.value)};
    const rememberMe = (event) => {setValue(!checked);};
    const emailChange = (event) => {setEmail(event.target.value)};

    return (
        <PrimeReactProvider>
            <Card title='Login' subTitle='Welcome back...' className="md:w-25rem">
        <div className="flex align-items-center justify-content-center">
          <div>
            <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                </span>
                <FloatLabel>
                    <InputText inputId='email' value={email} onChange={emailChange} tabIndex={1}/>
                    <label htmlFor='email' className="text-sm ml-2" fontsize='small'>email</label>
                </FloatLabel>
            </div>
            <div class="py-1 m-3 border-round">
            <div className="p-inputgroup flex">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-key"></i>
                </span>
            <FloatLabel>
                 <Password inputId='password' value={password} onChange={passwordEnter} feedback={false} tabIndex={2}  inputStyle={{width: "100%"}} style={{width: "100%"}} toggleMask />
                <label htmlFor='password' className="text-sm ml-2" fontsize='small'>Password</label>
             </FloatLabel>
             </div>
            </div>
            <div class="py-1 m-3 border-round">
                <Checkbox inputId="RememberMe" onChange={rememberMe} checked={checked}></Checkbox>
                <label htmlFor='RememberMe' className="text-sm ml-2" fontsize='small'>Remember Me</label>
            </div>
            <div class="flex align-items-center justify-content-center pt-2 gap-2">
                <Button label="Login" icon="pi pi-user"></Button>
                <Button label="Register" icon="pi pi-user-plus"></Button>
            </div>

            </div>
        </div>
        </Card>
        </PrimeReactProvider>
  );
}

export default Login;